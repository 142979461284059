import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";

import { Box, Container, Flex, Wrapper } from "../style/Grid";
import { Wysiwyg } from "../style/Wysiwyg";
import { Heading } from "../style/Heading";
import { List } from "../style/List";
import { OurFocusItem } from "../style/OurFocus/OurFocusItem";
import { CyberSec, M2m, SmartDevice } from "../style/Products";
import HeaderInner from "../components/HeaderInner";
import Meta from "../components/Meta";
import { parseContent } from "../utils/lang";

export default ({
  data,
  mobileMenuOpen,
  toggleMobileMenu,
  pageContext: { translations, links, lang, currentSlug },
}) => {
  const { frontmatter, html } = parseContent(data.markdownRemark, lang);

  return (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />
      <Wrapper bg="darkGreen" position="relative" zIndex={1000}>
        <HeaderInner
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          translations={translations}
          links={links}
          lang={lang}
          slug={currentSlug}
        />
      </Wrapper>

      <Wrapper bg="darkGreen">
        <Container py={[5, null, null, 80]}>
          <Heading as="h1" fontSize="h1" color="white" mb={5}>
            {frontmatter.title}
          </Heading>

          <Flex flexWrap="wrap" justifyContent="center">
            <OurFocusItem
              as={Link}
              to={links.products.cyberSecurity}
              variant="light"
            >
              <div>
                <CyberSec style={{ left: "57%" }} />
              </div>

              <Heading as="h3" fontSize={["default", "h4"]} mt={3}>
                {translations.productsCybersecurity}
              </Heading>
            </OurFocusItem>

            <OurFocusItem as={Link} to={links.products.m2m} variant="light">
              <div>
                <M2m />
              </div>
              <Heading as="h3" fontSize={["default", "h4"]} mt={3}>
                {translations.productsM2mCommunication}
              </Heading>
            </OurFocusItem>

            <OurFocusItem
              as={Link}
              to={links.products.protectionOfSmartDevices}
              variant="light"
            >
              <div>
                <SmartDevice />
              </div>
              <Heading as="h3" fontSize={["default", "h4"]} mt={3}>
                {translations.productsIot}
              </Heading>
            </OurFocusItem>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper py={[4, null, null, 5]}>
        <Container>
          <Box maxWidth={800} margin="auto">
            <Wysiwyg dangerouslySetInnerHTML={{ __html: html }} />

            <Flex
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="space-between"
              id="skoleni"
              mt={4}
            >
              {frontmatter.lectures.map((product, index) => (
                <Box
                  key={index}
                  bg="lightTitaniumGrey30"
                  p={3}
                  width={[1, null, "calc(50% - 10px)"]}
                  mb={[index === 0 && 3, null, 0]}
                >
                  <Heading as="h3" fontSize="h4" mb={3}>
                    {product.name}
                  </Heading>
                  {product.lists.map((list, index) => (
                    <Fragment key={index}>
                      <Heading as="h4" fontSize="regular" mt={4} mb={2}>
                        {list.title}
                      </Heading>

                      <List as="ul" variant="circle">
                        {list.items.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </List>
                    </Fragment>
                  ))}
                </Box>
              ))}
            </Flex>
          </Box>
        </Container>
      </Wrapper>
    </Fragment>
  );
};
export const pageQuery = graphql`
  query PRODUCTS_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        title_cz

        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }

        lectures {
          name
          lists {
            title
            items
          }
        }
        lectures_cz {
          name
          lists {
            title
            items
          }
        }
      }
    }
  }
`;
