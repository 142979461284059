import styled from "styled-components";
import { variant } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const OurFocusItem = styled(Box)`
  display: block;
  text-decoration: none;
  text-align: center;
  & > div {
    margin: 0 auto;
    position: relative;
    width: 100px;
    height: 100px;
    background-color: ${themeGet("colors.white")};
    border-radius: 200px;
    transition: background-color 400ms ease;
    svg {
      max-width: 110px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: fill 400ms ease;
    }
    @media screen and (min-width: ${themeGet("breakpoints.sm")}) {
      width: 170px;
      height: 170px;
      svg {
        max-width: none;
      }
    }
  }
  ${props =>
    variant({
      variants: {
        light: {
          h3: {
            color: "white",
          },
          "& > div": {
            svg: {
              fill: "cyberGreen",
            },
          },
          "&:hover": {
            h3: {
              color: "cyberGreen",
            },
            "& > div": {
              backgroundColor: "cyberGreen",
              svg: {
                fill: "white",
              },
            },
          },
        },
        dark: {
          h3: {
            color: "darkGreen",
          },
          "& > div": {
            svg: {
              fill: "cyberGreen",
            },
          },
          "&:hover": {
            "& > div": {
              backgroundColor: "darkGreen",
            },
          },
        },
      },
    })}
`;

OurFocusItem.defaultProps = {
  width: ["50%", "50%", "33%"],
  py: [3, null, 4],
  px: [2, null, 4],
};

export { OurFocusItem };
