import styled from "styled-components";
import { variant } from "styled-system";

import propTypes from "@styled-system/prop-types";
import { Box } from "../Grid/";

const List = styled(Box)`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 1em;
  }
  ${props =>
    variant({
      variants: {
        circle: {
          listStyle: "none",
          li: {
            position: "relative",
            marginLeft: "1em",
            marginBottom: 2,
            fontSize: 16,
            lineHeight: "19px",
          },
          "& > li::before": {
            position: "absolute",
            top: "4px",
            left: 0,
            content: "''",
            display: "inline-block",
            width: 10,
            height: 10,
            borderRadius: 50,
            marginLeft: "-1em",
            backgroundColor: "cyberGreen",
          },
        },
      },
    })}
`;

List.displayName = "List";

List.defaultProps = {
  as: "ul",
  display: "block",
  fontSize: 2,
  fontWeight: "regular",
  color: "textGrey",
};

List.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { List };
